import {callAPI} from "./apiWrapper";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const createParticipant = (queryParamas, landingURL)=>{
    let allqueryParmas = queryParamas + "&landingURL="+encodeURIComponent(landingURL)
    let userId = cookies.get('userId');
    allqueryParmas = allqueryParmas+"&cookieId="+userId

    return callAPI("GET", "createParticipant/?", allqueryParmas, {}).then((result) => {
		  return result;
    });
}

export const createBrowserData = (body)=>{
    try {
        let allqueryParmas = "";
        return callAPI("POST", "SurfInfo", allqueryParmas, body).then((result) => {
              return result;
        });
        
    } catch (error) {
        
    }
}

export const saveUserAnswer = (body)=>{
    let allqueryParmas = "";
    return callAPI("POST", "saveUserAnswer", allqueryParmas, body).then((result) => {
		  return result;
    });
}

export const userFailedInScreening = (body)=>{
    let allqueryParmas = "";
    return callAPI("POST", "userFailedInScreening", allqueryParmas, body).then((result) => {
		  return result;
    });
}

export const checkUserQuota = (body)=>{
    let allqueryParmas = "";
    return callAPI("POST", "checkUserQuota", allqueryParmas, body).then((result) => {
		  return result;
    });
}





